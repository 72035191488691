.loadingBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  max-width: 100%;
  position: relative;
  grid-column-start: 1;
  grid-column-end: -1;
  min-height: 0;
  padding: 8px;
}
.loadingCover {
  display: block;
  width: 100%;
  height: 100%;
  background: #0000004a;
  min-width: 0;
  min-height: 0;
  padding: 8px;
}
.freeBox {
  display: flex;
  position: absolute;
  flex-direction: column;
  left: 40%;
  top: 40%;
  z-index: 1;
}
.svg__k42 {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 200px;
  height: 200px;
  left: auto;
  top: auto;
  z-index: auto;
  color: #fff8f8;
  flex-shrink: 0;
}
