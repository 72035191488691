.root {
  display: grid;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  align-content: center;
  justify-items: center;
  justify-content: flex-start;
  align-items: center;
  background: var(--token-sK7ekqvPk);
  min-width: 0;
  min-height: 0;
  grid-template-columns:
    var(--plsmc-viewport-gap) 1fr minmax(0, var(--plsmc-wide-chunk))
    min(
      var(--plsmc-standard-width),
      calc(100% - var(--plsmc-viewport-gap) - var(--plsmc-viewport-gap))
    )
    minmax(0, var(--plsmc-wide-chunk)) 1fr var(--plsmc-viewport-gap);
}
:where(.root > *) {
  grid-column: 4;
}
.h1 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  text-align: center;
  min-width: 0;
}
.freeBox {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  grid-column-start: 3;
  grid-column-end: -3;
  padding: 8px;
}
.columns {
  display: flex;
  width: 100%;
  height: auto;
  flex-shrink: 1;
  flex-direction: row;
  max-width: 900px;
  min-width: 0;
  padding: 8px;
}
.columns > :global(.__wab_flex-container) {
  align-items: stretch;
  flex-direction: row;
  flex-wrap: nowrap;
  min-width: 0;
  margin-left: calc(0px - 300px);
  width: calc(100% + 300px);
  --plsmc-rc-col-gap: 300px;
}
.columns > :global(.__wab_flex-container) > *,
.columns > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.columns > :global(.__wab_flex-container) > picture > img,
.columns
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 300px;
}
.columns {
  --plsmc-rc-col-gap: 0px;
}
.columns > :global(.__wab_flex-container) > :nth-child(3n + 1) {
  width: calc((100% - 3 * var(--plsmc-rc-col-gap, 0px)) * 4 / 12);
}
.columns > :global(.__wab_flex-container) > :nth-child(3n + 2) {
  width: calc((100% - 3 * var(--plsmc-rc-col-gap, 0px)) * 4 / 12);
}
.columns > :global(.__wab_flex-container) > :nth-child(3n + 3) {
  width: calc((100% - 3 * var(--plsmc-rc-col-gap, 0px)) * 4 / 12);
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns {
    width: 100%;
    height: auto;
    flex-direction: row;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns > :global(.__wab_flex-container) {
    flex-direction: row;
    flex-wrap: wrap;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns > :global(.__wab_flex-container) > :nth-child(1n + 1) {
    width: calc((100% - 1 * var(--plsmc-rc-col-gap, 0px)) * 12 / 12);
  }
}
.column__pAnBs {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.adTemplate__vuYbw:global(.__wab_instance) {
  position: relative;
}
.adTemplate1__go9N:global(.__wab_instance) {
  position: relative;
}
.h2__zBSgq {
  text-align: center;
  color: #ffffff;
}
.text__peU9 {
  color: #ededec;
}
.column__rvFvZ {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.adTemplate__g5Sip:global(.__wab_instance) {
  position: relative;
}
.column__jNixa {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.adTemplate__cp53C:global(.__wab_instance) {
  position: relative;
}
.selectTemplate:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  margin: 14px 0px 0px;
}
