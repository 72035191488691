.root {
  display: flex;
  width: 100%;
  height: 100%;
  max-width: 100%;
  background: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  min-width: 0;
  min-height: 0;
  padding: 8px;
  border-width: 1px;
  border-style: none;
}
.freeBox__fFmT {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  max-width: 80%;
  top: auto;
  left: auto;
  min-width: 800px;
  min-height: 0;
  padding: 8px;
}
.freeBox__dPAqD {
  position: relative;
  width: auto;
  height: auto;
  left: auto;
  top: auto;
  z-index: 1;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
}
.h2__iyu0N {
  text-align: center;
  color: #ffffff;
}
.freeBox__oeZcI {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 60%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  left: auto;
  top: auto;
  z-index: 1;
  min-width: 0;
  padding: 0px;
  margin: 10px;
}
.slotTargetBody {
  color: #ffffff;
  text-align: center;
  font-size: 30px;
}
.callToAction:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  left: auto;
  top: auto;
  z-index: 1;
  align-self: center;
}
.svg__l1AVi {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.text__xwhmp {
  color: #ededec;
}
.svg___5CT6D {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.freeBox__eWeai {
  display: flex;
  position: absolute;
  flex-direction: column;
  left: 0px;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  top: 0px;
  right: 0px;
  bottom: 0px;
  min-width: 0;
  min-height: 0;
}
.img {
  position: relative;
  object-fit: cover;
  max-width: none;
  left: auto;
  top: auto;
  width: 100%;
  height: 100%;
  max-height: none;
  min-width: 0;
  min-height: 0;
}
.img > picture > img {
  object-fit: cover;
}
.img > :global(.__wab_img-spacer) > img {
  object-fit: cover;
}
.screenCover {
  display: block;
  width: 100%;
  height: 100%;
  background: #000000e6;
  opacity: 0.5;
  flex-shrink: 1;
  position: absolute;
  left: auto;
  top: auto;
  min-width: 0;
  min-height: 0;
  padding: 8px;
}
