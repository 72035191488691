.root {
  display: grid;
  position: relative;
  width: 100%;
  height: 100%;
  align-content: center;
  justify-items: center;
  background: var(--token-sK7ekqvPk);
  min-width: 0;
  min-height: 0;
  grid-template-columns:
    var(--plsmc-viewport-gap) 1fr minmax(0, var(--plsmc-wide-chunk))
    min(
      var(--plsmc-standard-width),
      calc(100% - var(--plsmc-viewport-gap) - var(--plsmc-viewport-gap))
    )
    minmax(0, var(--plsmc-wide-chunk)) 1fr var(--plsmc-viewport-gap);
  padding: 0px;
}
:where(.root > *) {
  grid-column: 4;
}
@media (min-width: 0px) and (max-width: 768px) {
  :where(.root > *) {
    grid-column: 4;
  }
}
.freeBox {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.h1 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  text-align: center;
  margin-bottom: 23px;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .h1 {
    font-size: 50px;
  }
}
.genericButton:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.text {
  padding-right: 0px;
}
