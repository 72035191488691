.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  position: relative;
  min-width: 0;
  padding: 8px;
}
.button {
  display: block;
  width: 350px;
  height: 350px;
  max-width: none;
  background: none;
  flex-shrink: 0;
  padding: 8px;
}
.buttonselected {
  width: 350px;
  height: 350px;
  max-width: none;
  background: var(--token-Bk31Q-boTwvF);
  flex-shrink: 0;
  padding: 8px;
  border: 1em solid var(--token-E4GUulfgc);
}
