.root {
  display: grid;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  align-content: center;
  justify-items: center;
  justify-content: flex-start;
  align-items: center;
  background: var(--token-sK7ekqvPk);
  min-width: 0;
  min-height: 0;
  grid-template-columns:
    var(--plsmc-viewport-gap) 1fr minmax(0, var(--plsmc-wide-chunk))
    min(
      var(--plsmc-standard-width),
      calc(100% - var(--plsmc-viewport-gap) - var(--plsmc-viewport-gap))
    )
    minmax(0, var(--plsmc-wide-chunk)) 1fr var(--plsmc-viewport-gap);
}
:where(.root > *) {
  grid-column: 4;
}
.freeBox__fuC4 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  grid-column-start: 3;
  grid-column-end: -3;
  padding: 8px;
}
.h1 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  text-align: center;
  margin-bottom: 18px;
  min-width: 0;
}
.freeBox___1ChN {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-bottom: 10px;
  min-width: 0;
}
.textInput:global(.__wab_instance) {
  max-width: 100%;
}
.svg___6AxfV {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.svg__s5XPc {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.generateButton:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.img {
  object-fit: cover;
  max-width: 100%;
  width: 100%;
  min-width: 0;
}
.img > picture > img {
  object-fit: cover;
}
.img > :global(.__wab_img-spacer) > img {
  object-fit: cover;
}
.freeBox___2Wc5U {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  margin: 10px 0px 0px;
}
.refreshButton:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__hsWLa {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 40px;
  height: 40px;
}
.selectButton:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.text {
  height: auto;
  padding-left: 0px;
}
.loadingBox:global(.__wab_instance) {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  grid-column-start: 1;
  grid-column-end: -1;
  min-height: 0;
  display: flex;
}
.svg__smS27 {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 200px;
  height: 200px;
  left: auto;
  top: auto;
  z-index: auto;
  color: #fff8f8;
}
