.root {
  display: grid;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  align-content: flex-start;
  justify-items: center;
  justify-content: flex-start;
  align-items: center;
  background: var(--token-sK7ekqvPk);
  padding-bottom: 0px;
  overflow: auto;
  min-width: 0;
  min-height: 0;
  grid-template-columns:
    var(--plsmc-viewport-gap) 1fr minmax(0, var(--plsmc-wide-chunk))
    min(
      var(--plsmc-standard-width),
      calc(100% - var(--plsmc-viewport-gap) - var(--plsmc-viewport-gap))
    )
    minmax(0, var(--plsmc-wide-chunk)) 1fr var(--plsmc-viewport-gap);
}
:where(.root > *) {
  grid-column: 4;
}
.freeBox__kQid {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  justify-self: flex-start;
  grid-column-start: 3;
  grid-column-end: -3;
  min-height: 0;
  padding: 50px 8px 8px;
}
.h1 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  text-align: center;
  margin-bottom: 18px;
  margin-top: 0px;
  min-width: 0;
}
.finalAdTemplate:global(.__wab_instance) {
  position: relative;
  height: 100%;
  flex-shrink: 0;
  min-height: 500px;
}
.h2__ky7Dd {
  text-align: center;
  color: #ffffff;
}
.text__yG7Yy {
  color: #ffffff;
  font-weight: 600;
}
.text__wmW95 {
  color: #ededec;
  position: relative;
  left: auto;
  top: auto;
  z-index: 1;
}
.freeBox__b3Fg9 {
  display: flex;
  position: relative;
  flex-direction: row;
  width: auto;
  height: auto;
  max-width: 100%;
  margin: 10px 0px 0px;
}
.freeBox__b3Fg9 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  margin-left: calc(0px - 10px);
  width: calc(100% + 10px);
}
.freeBox__b3Fg9 > :global(.__wab_flex-container) > *,
.freeBox__b3Fg9 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__b3Fg9 > :global(.__wab_flex-container) > picture > img,
.freeBox__b3Fg9
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 10px;
}
.downloadButton:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.shareButton:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  display: none;
}
