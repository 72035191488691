.root {
  display: grid;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  align-content: center;
  justify-items: center;
  justify-content: flex-start;
  align-items: center;
  background: var(--token-sK7ekqvPk);
  overflow: visible;
  min-width: 0;
  min-height: 0;
  grid-template-columns:
    var(--plsmc-viewport-gap) 1fr minmax(0, var(--plsmc-wide-chunk))
    min(
      var(--plsmc-standard-width),
      calc(100% - var(--plsmc-viewport-gap) - var(--plsmc-viewport-gap))
    )
    minmax(0, var(--plsmc-wide-chunk)) 1fr var(--plsmc-viewport-gap);
}
:where(.root > *) {
  grid-column: 4;
}
@media (min-width: 0px) and (max-width: 768px) {
  :where(.root > *) {
    grid-column: 4;
  }
}
.freeBox__nxAzr {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  max-width: 100%;
  justify-self: flex-start;
  max-height: none;
  overflow: visible;
  grid-column-start: 3;
  grid-column-end: -3;
  min-height: 0;
  padding: 8px;
}
.h1 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  text-align: center;
  margin-bottom: 13px;
  min-width: 0;
}
.columns {
  --plsmc-rc-col-gap: 0px;
}
.columns {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: stretch;
  flex-shrink: 1;
  flex-direction: row;
  flex-wrap: nowrap;
  min-width: 0;
  min-height: 0;
  padding: 8px;
}
.columns > :nth-child(2n + 1) {
  width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 9 / 12);
}
.columns > :nth-child(2n + 2) {
  width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 3 / 12);
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns {
    width: 100%;
    height: auto;
    flex-direction: row;
    flex-wrap: wrap;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns > :nth-child(1n + 1) {
    width: calc((100% - 1 * var(--plsmc-rc-col-gap, 0px)) * 12 / 12);
  }
}
.column__n05XM {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.freeBox__ieCwN {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  max-width: 100%;
  min-width: 0;
  min-height: 0;
  padding: 0px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__ieCwN {
    max-width: none;
    justify-content: flex-start;
    align-items: center;
  }
}
.freeBox__nwm7Z {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  align-self: center;
  max-width: none;
  max-height: 90%;
  min-width: 0;
  min-height: 0;
  padding: 8px;
}
.freeBox__t1SZx {
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-bottom: 10px;
  min-width: 0;
  display: none;
}
.textInput:global(.__wab_instance) {
  max-width: 100%;
}
.svg___8BiLq {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.svg__l7Niv {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.generateButton:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.adTemplate1:global(.__wab_instance) {
  max-width: none;
  position: relative;
  height: 100%;
  flex-shrink: 0;
  flex-grow: 0;
  align-self: auto;
  max-height: none;
  min-height: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .adTemplate1:global(.__wab_instance) {
    width: 100%;
    height: 100%;
    min-height: 500px;
    min-width: 0;
  }
}
.h2___8JUdz {
  text-align: center;
  color: #ffffff;
}
.text__edznr {
  text-align: center;
  font-size: 20px;
  width: 100%;
  height: auto;
  flex-shrink: 0;
  flex-grow: 0;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .text__edznr {
    left: auto;
    top: auto;
    z-index: 1;
    position: relative;
  }
}
.text__aexHb {
  color: #ededec;
}
.freeBox__g8XkX {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  margin: 10px 0px 0px;
}
.resetButton:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__bbSec {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 40px;
  height: 40px;
}
.selectButton:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.column__r2LNa {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.componentList {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.changeTitle {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-bottom: 12px;
  min-width: 0;
  padding: 0px;
}
.collapseAble1:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  height: 100%;
  min-width: 0;
  min-height: 0;
}
.svg__ze4KL {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 35px;
  height: 35px;
}
.titleInput:global(.__wab_instance) {
  max-width: 100%;
  margin-top: 8px;
  margin-bottom: 8px;
}
.svg__eedcF {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.svg__acRyA {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.changeBody {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-bottom: 12px;
  min-width: 0;
  padding: 0px;
}
.collapseAble:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  height: 100%;
  min-width: 0;
  min-height: 0;
}
.svg__bkbl5 {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 35px;
  height: 35px;
}
.bodyArea:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
  height: 161px;
  flex-shrink: 0;
  margin: 8px;
}
.changeButton {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-bottom: 12px;
  min-width: 0;
  padding: 0px;
}
.collapseAble13:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  height: 100%;
  min-width: 0;
  min-height: 0;
}
.svg__zjnWo {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 35px;
  height: 35px;
}
.buttonIntput:global(.__wab_instance) {
  max-width: 100%;
  margin-top: 8px;
  margin-bottom: 8px;
}
.svg__eAm20 {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.svg__x66K6 {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.changeImage {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-bottom: 18px;
  min-width: 0;
  padding: 0px;
}
.collapseAble12:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  height: 100%;
  min-width: 0;
  min-height: 0;
}
.svg__oxXll {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 35px;
  height: 35px;
}
.freeBox___1K5Rv {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-top: 8px;
  margin-bottom: 8px;
  min-width: 0;
  padding: 0px;
}
.generateImgInput:global(.__wab_instance) {
  max-width: 100%;
}
.svg__m8Sit {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.svg__znQ8E {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.getImgButton:global(.__wab_instance) {
  position: relative;
  width: 50px;
  flex-shrink: 0;
}
.changeDestination {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-bottom: 12px;
  min-width: 0;
  padding: 0px;
}
.collapseAble2:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  width: 100%;
  height: 100%;
  min-width: 0;
  min-height: 0;
}
.svg__rFbie {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 35px;
  height: 35px;
}
.destinationLinkInput:global(.__wab_instance) {
  max-width: 100%;
  margin-top: 8px;
  margin-bottom: 8px;
}
.svg__kpSkv {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.svg___2NT3W {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
